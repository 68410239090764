@import '../../common/colors';

.content {
	width: 200px;
	padding: 10px;
	padding-top: 15px;
	padding-bottom: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	background-color: #fff;

	& > a {
		width: 50%;
		margin-bottom: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-decoration: none;
		cursor: pointer;

		& > img {
			width: 40px;
			height: 40px;
			margin-bottom: 6px;
		}

		& > span {
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 20px;
			text-align: center;
			letter-spacing: 0.02em;

			color: black;
		}
	}
}

.menuBtn {
	width: 30px;
	min-width: 30px !important;
}
